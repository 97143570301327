import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from '../ngrx/data.reducer';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Injectable()
export class UserService {
  user$ = this.store.select(selectUser);
  userDetails;
  private destroy$ = new Subject<void>();
  constructor(private store: Store) {
    this.user$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((user) => {
        this.userDetails = user;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  loginDataSet(response) {
    let Data = {
      ...(this.userDetails || {}),
      email: response?.[0]?.email || null,
      fullName: response?.[0]?.fullName || null,
      id: response?.[0]?.id || null,
      phone: response?.[0]?.phone || null,
      countryCode: response?.countryCode || null,
      phoneCode: response?.phoneCode || null,
      loggedIn: response?.loggedIn || null,
      profileImage: response?.[0]?.profileImage || null,
      registeredFrom: response?.[0]?.registeredFrom || null,
      timeZone: response?.[0]?.timeZone || null,
      userType: response?.[0]?.userType || null,
      userTypeId: response?.[0]?.userTypeId || null,
      deviceID: response[1]?.deviceID || null,
      refreshToken: response[1]?.refreshToken || null,
      token: response[1]?.token || null,
      isOnBoardingCompleted:
        response?.[0]?.userTypeId === 1
          ? response?.[0]?.mentorDetails?.[0]?.isOnBoardingCompleted
          : false,
      currentCountry: null,
      chooseProfile: false,
      aboutMe: response?.[0]?.mentorDetails?.[0]?.aboutMe || null,
      shortBio: response?.[0]?.mentorDetails?.[0]?.shortBio || null,
      uniqueName: response?.[0]?.mentorDetails?.[0]?.uniqueName || null,
      userName: response?.[0]?.mentorDetails?.[0]?.userName || null,
      socialLink: response?.[0]?.mentorDetails?.[0]?.socialLink || null,
      otherCategory: response?.[0]?.mentorDetails?.[0]?.otherCatagory || null,
      verificationPostUrl:
        response?.[0]?.mentorDetails?.[0]?.verificationPostUrl || null,
      profileVideoUrl:
        response?.[0]?.mentorDetails?.[0]?.profileVideoUrl || null,
      mentorSlotBuffer:
        response?.[0]?.mentorDetails?.[0]?.mentorSlotBuffer || null,
      supportMentorDefaultAmount:
        response?.[0]?.mentorDetails?.[0]?.supportMentorDefaultAmount || null,
      isProfileCompleted:
        response?.[0]?.mentorDetails?.[0]?.isProfileCompleted || null,
      zoomAccessTokenEnc:
        response?.mentorDetails?.[0]?.zoomAccessTokenEnc !== ''
          ? response?.mentorDetails?.[0]?.zoomAccessTokenEnc
          : null,
      zoomRefreshTokenEnc:
        response?.mentorDetails?.[0]?.zoomRefreshTokenEnc !== ''
          ? response?.mentorDetails?.[0]?.zoomRefreshTokenEnc
          : null,
      zoomKey:
        response?.mentorDetails?.[0]?.zoomKey !== ''
          ? response?.mentorDetails?.[0]?.zoomKey
          : null,
      zoomAesiv:
        response?.mentorDetails?.[0]?.zoomAesiv !== ''
          ? response?.mentorDetails?.[0]?.zoomAesiv
          : null,
      isApproved: response?.[0]?.mentorDetails?.[0]?.isApproved || null,
      applyApproval: response?.[0]?.mentorDetails?.[0]?.applyApproval || null,
      googleCalendarAccessToken:
        response?.[0]?.mentorDetails?.[0]?.googleCalendarAccessToken || null,
      googleCalendarRefreshToken:
        response?.[0]?.mentorDetails?.[0]?.googleCalendarRefreshToken || null,
      microsoftCalendarAccessToken:
        response?.[0]?.mentorDetails?.[0]?.microsoftCalendarAccessToken || null,
      microsoftCalendarRefreshToken:
        response?.[0]?.mentorDetails?.[0]?.microsoftCalendarRefreshToken ||
        null,
    };
    let filteredObject: any = Object.fromEntries(
      Object.entries(Data).filter(([key, value]) => value !== null),
    );
    return filteredObject;
  }
  userDataSet(response) {
    let Data = {
      ...(this.userDetails || {}),
      email: response?.email || null,
      fullName: response?.fullName || null,
      id: response?.id || null,
      phone: response?.phone || null,
      countryCode: response?.countryCode || null,
      phoneCode: response?.phoneCode || null,
      loggedIn: response?.loggedIn || null,
      profileImage: response?.profileImage || null,
      registeredFrom: response?.registeredFrom || null,
      timeZone: response?.timeZone || null,
      userType: response?.userType || null,
      userTypeId: response?.userTypeId || null,
      deviceID: response[1]?.deviceID || null,
      refreshToken: response[1]?.refreshToken || null,
      token: response[1]?.token || null,
      isOnBoardingCompleted:
        response?.userTypeId === 1
          ? response?.mentorDetails?.[0]?.isOnBoardingCompleted
          : false,
      currentCountry: null,
      chooseProfile: false,
      aboutMe: response?.mentorDetails?.[0]?.aboutMe || null,
      shortBio: response?.mentorDetails?.[0]?.shortBio || null,
      uniqueName: response?.mentorDetails?.[0]?.uniqueName || null,
      userName: response?.mentorDetails?.[0]?.userName || null,
      socialLink: response?.mentorDetails?.[0]?.socialLink || null,
      otherCategory: response?.mentorDetails?.[0]?.otherCatagory || null,
      verificationPostUrl:
        response?.mentorDetails?.[0]?.verificationPostUrl || null,
      profileVideoUrl: response?.mentorDetails?.[0]?.profileVideoUrl || null,
      mentorSlotBuffer: response?.mentorDetails?.[0]?.mentorSlotBuffer || null,
      supportMentorDefaultAmount:
        response?.mentorDetails?.[0]?.supportMentorDefaultAmount || null,
      isProfileCompleted:
        response?.mentorDetails?.[0]?.isProfileCompleted || null,
      zoomAccessTokenEnc:
        response?.mentorDetails?.[0]?.zoomAccessTokenEnc !== ''
          ? response?.mentorDetails?.[0]?.zoomAccessTokenEnc
          : null,
      zoomRefreshTokenEnc:
        response?.mentorDetails?.[0]?.zoomRefreshTokenEnc !== ''
          ? response?.mentorDetails?.[0]?.zoomRefreshTokenEnc
          : null,
      zoomKey:
        response?.mentorDetails?.[0]?.zoomKey !== ''
          ? response?.mentorDetails?.[0]?.zoomKey
          : null,
      zoomAesiv:
        response?.mentorDetails?.[0]?.zoomAesiv !== ''
          ? response?.mentorDetails?.[0]?.zoomAesiv
          : null,
      googleMeetAccessToken: response?.model?.mentorDetails?.[0]?.googleMeetAccessToken,
      googleMeetAesiv: response?.model?.mentorDetails?.[0]?.googleMeetAesiv,
      googleMeetKey: response?.model?.mentorDetails?.[0]?.googleMeetKey,
      googleMeetRefreshToken: response?.model?.mentorDetails?.[0]?.googleMeetRefreshToken,
      isApproved: response?.mentorDetails?.[0]?.isApproved || null,
      applyApproval: response?.mentorDetails?.[0]?.applyApproval || null,
      googleCalendarAccessToken:
        response?.mentorDetails?.[0]?.googleCalendarAccessToken || null,
      googleCalendarRefreshToken:
        response?.mentorDetails?.[0]?.googleCalendarRefreshToken || null,
      microsoftCalendarAccessToken:
        response?.mentorDetails?.[0]?.microsoftCalendarAccessToken || null,
      microsoftCalendarRefreshToken:
        response?.mentorDetails?.[0]?.microsoftCalendarRefreshToken || null,

      bankName: response?.bankAccountDetails?.[0]?.bankName || null,
      bankAccountName: response?.bankAccountDetails?.[0]?.accountName || null,
      bankAccountNumber: response?.bankAccountDetails?.[0]?.accountNumber || null,
      bankCountry: response?.bankAccountDetails?.[0]?.country || null,
    };
    let filteredObject: any = Object.fromEntries(
      Object.entries(Data).filter(
        ([key, value]) => value !== null && value !== '',
      ),
    );
    return filteredObject;
  }
}
